.navigation {
    position: absolute;
}

.navigation .toggle {
    width: 45px;
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: -200px;
    transition: left 0.3s ease;
}

.menu button {
    margin: 10px 16px;
    padding: 6px 40px;
    border-radius: 4px;
    background-color: rgb(83, 83, 83);
    border: none;
    color: white;

    font-family: 'Poppins', sans-serif;
}

.menu button:not(.chosen) {
    cursor: pointer;
}

.menu button.chosen {
    background-color: gray;
    text-decoration: underline;
}