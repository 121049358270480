.valo-infos {
    width: 150px;
    display: flex;
    align-items: center;
    text-align: left;
}

.valo-infos .kda {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    text-align: left;
    max-width: 100px;
}

.valo-infos .kda p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.valo-infos img {
    width: 55px;
    border-radius: 100%;
    margin-right: 4px;
}

.valo-stats p {
    width: 80px;
    font-size: 12px;
    margin: 0;
}

.valo-stats .hs {
    margin-bottom: 14px;
}


.game .valo-date {
    margin-bottom: 8px;
}

.game .score {
    font-weight: 900;
    margin-bottom: 8px;
}

.details.draw {
    background-color: #666666;
}

.details-vcontainer {
    margin: 6px 0px;
}