.valo-summary {
    display: flex;
    justify-content: space-between;
    background-color: #282830;
    text-align: center;
}

.valo-summary .rounds {
    word-wrap: break-word;
    width: 450px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
}

.valo-summary .rounds .round {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2px;
}

.valo-summary .rounds .round p {
    margin: 0;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
}

.valo-summary .rounds .round img {
    width: 25px;
}

.valo-summary .team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
}

.valo-summary .team p {
    margin: 0;
    font-size: 14px;
    color: #99abbf;
}