.summary {
    height: 50px;
    background-color: #282830;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary .team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.summary .team p {
    margin: 0;
    font-size: 10px;
    margin: 0px 28px;
}

.summary .graph {
    width: 400px;
    height: 15px;
    display: flex;
    position: relative;

    margin: 8px 0px;
}

.summary .graph p {
    font-size: 10px;
    margin: 0;
    position: absolute;
    margin: 2px 0px;
}

.summary .graph .title {
    left: calc(50% - 15px);
}

.summary .graph .data-left {
    top: 0px;
    left: 8px;
    text-align: left;
}

.summary .graph .data-right {
    top: 0px;
    right: 8px;
    text-align: left;
}