@import url('https://fonts.googleapis.com/css2?family=Poppins&Roboto&Lato:wght@900&display=swap');

.tracker {
    margin: 24px 0px;
}

.tracker h1 {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
}

.content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    height: 100px;
    width: 700px;
    border-radius: 4px;
    overflow: hidden;

    border-left-width: 6px;
    border-left-style: solid;

    font-family: 'Roboto', sans-serif;
}

.items {
    display: flex;
    margin-top: 8px;
}

.items img {
    width: 20px;
    margin: 0px 1px;

    border-radius: 4px;
}

.items .blank-item {
    width: 20px;
    height: 20px;
    margin: 0px 1px;

    border-radius: 4px;
}

.champion {
    width: 50px;
    border-radius: 100%;
}

.upper {
    display: flex;
    align-items: center;
}

.upper .kda {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-left: 8px;
    text-align: left;
    max-width: 100px;
}

.upper .kda p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.summoner {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.summoner img {
    width: 20px;
}

.stats p {
    font-size: 12px;
    margin: 0;
}

.game {
    padding: 4px;
    font-size: 12px;
    margin-left: 6px;
}

.game p {
    margin: 0;
}

.game .date {
    margin-bottom: 14px;
}

.participants {
    display: flex;
    padding: 5px;
}

.participant {
    display: flex;
    position: relative;
    align-items: center;
    font-size: 12px;
    margin: 0px 5px;
}

.participant p {
    margin: 0;
}

.participant img {
    width: 16px;
    max-width: 100%;
    margin-right: 4px;
}

.details {
    height: 100%;
    width: 30px;

    background-color: #703c47;
    position: relative;
    display: flex;
    justify-content: center;
}

.details.win {
    background-color: #2f436e;
}

.details:hover {
    opacity: 40%;
    cursor: pointer;
}

.details .game-icon {
    width: 16px;
    position: absolute;
    top: 12px;
}

.details .arrow {
    width: 14px;
    position: absolute;
    bottom: 6px;
}

.details-container .options {
    display: flex;
    justify-content: center;
    margin: 4px 0px;
    padding: 0;

    background-color: #282830;
    border-radius: 6px;
}

.details-container .options button {
    margin: 10px 40px;
    padding: 6px 40px;
    border-radius: 4px;
    background-color: transparent;
    border: none;
    color: white;

    font-family: 'Poppins', sans-serif;
}

.details-container .options button:not(.chosen) {
    cursor: pointer;
}

.details-container .options button.chosen {
    font-weight: 900;
    background-color: #515163;
}

.details-container .options button:not(.chosen):hover {
    background-color: #1c1c1f;
}







.game {
    width: 90px;
}

.infos {
    width: 150px;
}

.stats {
    width: 80px;
}

.participants {
    width: 185px;
}