.team {
    font-family: 'Roboto', sans-serif;
}

.team .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #31313c;
}

.team .title p {
    text-align: center;
    font-size: 11px;
}

.team .title :nth-child(1) {
    width: 140px;
}

.team .title :nth-child(2) {
    width: 50px;
}

.team .title :nth-child(3) {
    width: 50px;
}

.team .title :nth-child(4) {
    width: 50px;
}

.team .title :nth-child(5) {
    width: 50px;
}

.team .title :nth-child(6) {
    width: 160px;
}

.team .body {
    padding-bottom: 4px;
}

.team .body .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team .body .card .player {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 6px;
    width: 140px;
}

.team .body .card .player .champion {
    width: 30px;
    border-radius: 100%;
}

.team .body .card .player .summoner img {
    width: 15px;
}

.team .body .card .player .name p {
    font-size: 10px;
    margin: 0;
}

.team .body .card .kda {
    text-align: center;
    width: 50px;
}

.team .body .card .kda p {
    margin: 0;
    font-size: 10px;
}

.team .body .card .damage {
    text-align: center;
    width: 50px;
}

.team .body .card .damage p {
    margin: 0;
    font-size: 10px;
}

.team .body .card .wards {
    text-align: center;
    width: 50px;
}

.team .body .card .wards p {
    margin: 0;
    font-size: 10px;
}

.team .body .card .cs {
    text-align: center;
    width: 50px;
}

.team .body .card .cs p {
    margin: 0;
    font-size: 10px;
}

.team .body .card .items {
    text-align: center;
    width: 160px;
}