.valo-team {
    font-family: 'Roboto', sans-serif;
}

.valo-team .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #31313c;
}

.valo-team .title p {
    text-align: center;
    font-size: 11px;
}

.valo-team .title :nth-child(1) {
    width: 140px;
}

.valo-team .title :nth-child(2) {
    width: 50px;
}

.valo-team .title :nth-child(3) {
    width: 50px;
}

.valo-team .title :nth-child(4) {
    width: 50px;
}

.valo-team .title :nth-child(5) {
    width: 50px;
}

.valo-team .title :nth-child(6) {
    width: 50px;
}

.valo-team .title :nth-child(7) {
    width: 50px;
}

.valo-team .body .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.valo-team .body .card .player {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 6px;
    width: 140px;
}

.valo-team .body .card .player .agent {
    width: 30px;
    border-radius: 100%;
}

.valo-team .body .card .player .rank {
    width: 25px;
}

.valo-team .body .card .player p {
    font-size: 10px;
    margin: 0;
}

.valo-team .body .card .score {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .score p {
    margin: 0;
    font-size: 10px;
}

.valo-team .body .card .kda {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .kda p {
    margin: 0;
    font-size: 10px;
}

.valo-team .body .card .hs {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .hs p {
    margin: 0;
    font-size: 10px;
}

.valo-team .body .card .fb {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .fb p {
    margin: 0;
    font-size: 10px;
}

.valo-team .body .card .plants {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .plants p {
    margin: 0;
    font-size: 10px;
}

.valo-team .body .card .desamo {
    text-align: center;
    width: 50px;
}

.valo-team .body .card .desamo p {
    margin: 0;
    font-size: 10px;
}