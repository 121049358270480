.container {
    display: flex;
    flex-direction: column;
    user-select: none;
    align-items: center;
}

.click {
    width: 400px;
    height: 200px;
    border: 1px solid darkgray;
    text-align: center;
    cursor: pointer;
}

.click:hover {
    background-color: rgba(128, 128, 128, 0.5);
}

.click:active {
    background-color: rgba(59, 59, 59, 0.6);
}