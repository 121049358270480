@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.statistics {
    background-color: #282830;
    padding: 14px 8px;
    border-radius: 6px;
}

.statistics .spacer {
    display: flex;
    margin: 10px 0px;
}

.player {
    display: flex;
    align-items: center;
    margin: 4px 0px;
}

.player img {
    width: 28px;
    border-radius: 100%;
    margin-right: 8px;
}

.player .graph {
    height: 20px;
    display: flex;
    position: relative;

    margin: 8px 0px;
}

.player .damage-container {
    display: flex;
    align-items: center;
    margin-left: 4px;
}

.player .damage-container .damage {
    margin: 0;
    padding: 0;

    font-size: 10px;
    font-family: 'Poppins', sans-serif;
}